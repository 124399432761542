
.container {
  padding-bottom: 20px;
  padding-top: 20px;
}

.card {
  height: auto;
  margin-bottom: 30px;
  padding: 20px;
  background-color: #fff;
  border-radius: 10px;
  border: 2px solid #e2e9e9;
  border-bottom-width: 4px;
  transition: all 0.1s;
}

.block {
  width: 100%;
  padding: 20px;
  border: 2px solid #ccc;
  border-radius: 10px;
}

.words {
  overflow-y: scroll;
  max-height: 400px;
  &::-webkit-scrollbar-thumb {
    background-color: rgb(102, 102, 102);
    border: 4px solid transparent;
    border-radius: 8px;
    background-clip: padding-box;
  }

  &::-webkit-scrollbar {
    width: 16px;
  }
}

.normal-flag {
  margin-bottom: -0.1em !important;
}

.columns.has-borders {
  .column:first-child {
    border-right: 2px solid #e2e9e9;
  }
}

.tag {
  display: block;
  max-width: 100%;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
  line-height: 1.5rem;
}
